<!-- https://medium.com/js-dojo/promoting-your-vue-js-pwa-installation-6bd112342c19 -->


<template>

    <div v-if="deferredPrompt" class="absolute bottom-0 left-0 h-full w-full z-10 flex items-center justify-center ">

        <div class="bg-bgcolor w-4/5 h-1/5 p-5">
            <p> {{ store.state.content.pwa.text }} </p>
            <!-- <div class="flex flex-col justify-around"> -->
                <div class="flex flex-row justify-around mt-12">
                    <button class="btn-small" text @click="dismiss"> {{ store.state.content.pwa.nook }} </button>
                    <button class="btn-small" text @click="install"> {{ store.state.content.pwa.ok }} </button>
                </div>
            <!-- </div> -->
            
        </div>

    </div>

  </template>
  
  

<script>
import { onMounted, ref } from 'vue'; // computed, inject 
import { useStore } from 'vuex';
import Cookies from "js-cookie";

  
export default {

    name: "AddToHome2",

    setup () {

        const deferredPrompt = ref(null);
        const store = useStore();

        onMounted(() => {
            console.log("mounted")
            
            // window.addEventListener("beforeinstallprompt", e => {
            window.addEventListener('beforeinstallprompt', (e) => {
                console.log("beforeinstallprompt")
                e.preventDefault();
                // Stash the event so it can be triggered later.
                if (Cookies.get("add-to-home-screen") === undefined) {
                    console.log("beforeinstallprompt cookie check")
                    deferredPrompt.value = e;
                }
            });

            window.addEventListener("appinstalled", () => {
                console.log("appinstalled")
                deferredPrompt.value = null;
            });

            console.log(isPwa())
        })

        const dismiss = async  () => {
            console.log("dismiss")
            Cookies.set("add-to-home-screen", null, { expires: 30 });  // 30 Tage
            deferredPrompt.value = null;
        }

        const install = async () => {
            console.log("install")
            deferredPrompt.value.prompt();
        }
        
        // https://stackoverflow.com/questions/41742390/javascript-to-check-if-pwa-or-mobile-web
        const isPwa = () => {
            return ["fullscreen", "standalone", "minimal-ui"].some(
                (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
            );
        }


        return {
            deferredPrompt,
            store,
            dismiss,
            install,
        }
    }

}

</script>


<style scoped>

</style>