<template>
    
    <footer id="main-footer" class="bg-fgcolor text-white">

        <div class="">
          
                <!-- <VertLine></VertLine> -->
                <!-- <div class="grid col-start-1 content-center justify-center">
                    <div class="bg-black w-px h-6" ></div>
                </div> -->

                <div class="flex flex-col sm:flex-row place-content-around">
                  <div class="flex justify-center place-items-center">
                        <p class="footer-item" :style="getOpacity(0)" @click="openDatenschutz()"> {{ content.footer[language][0] }} </p>
                        <!-- <div class="bg-black h-0.5px w-7 mb-1" :class="store.state.mainId==4?'visible':'invisible'"></div> -->
                    </div>
                    <div class="justify-center place-items-center">
                        <p class="footer-item" :style="getOpacity(1)" @click="openImpressum()"> {{ content.footer[language][1] }} </p>
                        <!-- <div class="bg-black h-0.5px w-7 mb-1" :class="store.state.mainId==2?'visible':'invisible'"></div> -->
                    </div>
                    <div class="grid grid-row-1 justify-center place-items-center">
                        <p class="footer-item" :style="getOpacity(2)" @click="openQuiz()"> {{ content.footer[language][2] }} </p>
                        <!-- <div class="bg-black h-0.5px w-7 mb-1" :class="store.state.mainId==3?'visible':'invisible'"></div> -->
                    </div>
                   
                </div>
                
        </div>  
      
    </footer>

</template>



<script>
// import routerMixin from "../mixins/routerMixin";
// import myRouter from '../mixins/myRouter.js'
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';  // , useRoute
// import useEmitter from '@/composables/useEmitter';

// import VertLine from "VertLine.vue";
// import VertLine from './VertLine.vue';

export default {
  
  name: 'FooterComp',
  // mixins: [routerMixin],

  components: {
        // VertLine
  },

  props: {
    content: Object,
    language: String
  },
  
  setup(){

    const store = useStore();
    // const {pageName, pushPage} = myRouter();
    const router = useRouter();
    const route = useRoute();
    // const emitter = useEmitter();

    const getOpacity = (val) => {

      if (val == store.state.page) {
        return { opacity: 0.5}
      } else {
        return { opacity: 1.0}
      }
    }

    const openQuiz = () => {

      if (store.state.page != 2) {
        // emitter.emit("GO_HOME", "message from header" );
        store.dispatch('resetFragen');
        store.state.page = 2;
        router.push({ name: 'quiz', props: true})
        // console.log("goQuiz")
        scrollPage()
      }
     
    }

    const openImpressum = () => {
      if (store.state.page != 1) {
        store.state.page = 1;
        router.push({ name: 'impressum', props: true})
        // pushPage('impressumPage')
        scrollPage()
      }
      
    }

    const openDatenschutz = () => {
      // store.state.mainId =4;
      if (store.state.page != 0) {
        store.state.page = 0;
        router.push({ name: 'datenschutz', props: true})
        // pushPage('impressumPage')
        scrollPage()
      }
    }
    
    const scrollPage = () => {
      setTimeout(() => {
          // console.log("scroll")
          window.scrollTo(0, 0);
      }, 300);
    }

    // const defuscate = (elm) => {
    //   elm.innerHTML=atob(elm.getAttribute('data-meta').split('').reverse().join('')).split('').reverse().join('');
    // }

    // // Obfuscate function (not used)
    // const obfuscate = (text) => {
    //   return btoa(text.split('').reverse().join('')).split('').reverse().join('');
    // }


    return {
       
        route,
        store,
        
        getOpacity,
        openImpressum,
        openDatenschutz,
        openQuiz,
      
      }

  }
}

</script>



<style scoped>

</style>