<template>

    <div class="grid place-items-center opacity-30">     
        <h5 class="whitespace-pre-wrap">{{ txt }}</h5>
    </div>

</template>



<script>
import { ref, onMounted, onUnmounted } from 'vue';


export default {
    
    name:"VueLoading",

    props : {
        text: {
            type: String,
            default: "hallo"
        }
    },

    setup(props) {
        const txt = ref(props.text + "");
        const cnt = ref(0);
        let tId = 0;

        const nextLoadStep = () => {
            
            cnt.value++;
            if (cnt.value == 4){
                cnt.value = 0;
            } 

            if (cnt.value == 0) {
                txt.value = props.text + "";
            } else  if (cnt.value == 1) {
                txt.value = props.text + "";
            } else  if (cnt.value == 2) {
                txt.value = props.text + "";
            } else  if (cnt.value == 3) {
                txt.value = props.text + "";
            }

            tId = setTimeout(() => {
                nextLoadStep();
            }, 500);

        }

        onMounted(() => {
            tId = setTimeout(() => {
                nextLoadStep();
            }, 1000);
        })


        onUnmounted(() => {
            clearTimeout(tId)
        })

        return {
            txt
        }
    }
}


</script>