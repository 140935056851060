import { createApp } from 'vue'
import App from './App.vue'

import './registerServiceWorker'

import router from './router'
import store from './store'

import "./css/transitions.css";
import './tailwind.css'
// import './assets/tailwind.css'

// // https://github.com/johanaarstein/dotlottie-player-light
// import '@johanaarstein/dotlottie-player-light';

// import { DotLottiePlayer } from '@johanaarstein/dotlottie-player'
// .component('DotLottiePlayer', DotLottiePlayer)

createApp(App).use(store).use(router).mount('#app')