<template>
    
    <transition name="fadepage" mode="out-in" appear>
    <div class="grid grid-cols-1 grid-rows-3 justify-items-stretch absolute top-0 gap-2 left-0 w-full h-full z-10 bg-white" v-if="store.state.loaded==false&&store.state.started==false">
            
            <div class="flex flex-col place-content-center">
                <div class="w-full h-1/6"></div>
                <div class="w-2/3 h-4/6 self-center align-center bg-center bg-[url('~@/assets/svgs/logo-eltville.svg')] bg-contain bg-no-repeat"> </div>
                <div class="w-full h-1/6"></div>
            </div>
            
            <div class="flex flex-col self-center content-center items-center self-center place-content-center">
                <h2 class="text-center text-bgcolor w-6/7 " v-html="titel"></h2>
                <button class="btn" @click='onStart()'>START</button>
            </div>
            
            <div class="flex flex-col place-content-center">
                <div class="w-full  md:h-1/6"></div>
                <div class="w-2/4 h-4/6 self-center content-center bg-center bg-[url('~@/assets/svgs/logo-burgverein.svg')] bg-contain bg-no-repeat"> </div> 
                <div class="w-full h-1/6"></div>
                <!-- <div class="w-1/2 h-3/4 self-center bg-orange-500"> </div> -->
            </div>

    </div>
    </transition>

</template>



<script>
import { useStore } from 'vuex';


export default {

    name: "vue-splash",

    props: {
        'titel':String,
    },

    setup() {

        const store = useStore();

        const onStart = () => { 
            console.log("onStart")
            store.state.started=true;
        }

        return {
            onStart,
            store
        }
    }


   

   

    // computed: {

    //     isTimeout(){
    //     // console.log("computed in VueContent image")
    //     return  this.$store.getters.getTimeoutFlag;
    //     },

    //     // onStart(){
    //     //     console.log("onStart")
    //     //     return this.$store.getters.getAppLoaded;
    //     // },



    //     appLanguage() {
    //     // console.log("computed appLanguage");
    //     // console.log(this.$store.getters.getAppLanguage);
    //     return this.$store.getters.getAppLanguage;
    //     }
    // }

}
</script>



<style scoped>

/* #box-content {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 1.0;
    z-index: 1000;
} */

/* .center-me {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: auto;
} */

.p-height{
    height: 300px;
    width: 1000px;
    overflow-y:scroll;
    background-color: rgb(255, 255, 255);
}

/* #hg {
    position: absolute;
    background-color: rgb(255, 255, 255);
    top: 0px;
    left: 0px;
    width: 1920px;
    height: 1080px;
    opacity: 1.0;
} */

h2 {
    padding-bottom: 10px;
}

.bu-head {
    font-family: system-ui;
    font-size: 30px;
}

.bu-text {
    font-family: system-ui;
    font-size: 20px;
}

p::-webkit-scrollbar {
  width: 20px;
}

p::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255); 
}

p::-webkit-scrollbar-thumb {
  background-color: rgb(247, 0, 0);
}

</style>