import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/quiz',
    name: 'quiz',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuizView.vue')
  },
  {
    path: '/impressum',
    name: 'impressum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ImpressumView.vue')
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DatenschutzView.vue')
  },
  {
    path: '/cache',
    name: 'cache',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Mp3DownloadView.vue')
  },
  {
    path: '/mp3player',
    name: 'mp3player',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Mp3PlayerView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach( (to, from, next) => {
//   console.log(to,from, next);
//   next(false) 
//     return ''
// })

router.beforeEach( (to, from, next) => {
  // console.log("xxx", window.event.type);
  // console.log(from.name);
  // console.log(to,from, next);
  // console.log(window)

  if (from.name == undefined ) {
    next();
  
  } else if ( (window.event.type == 'popstate' && from.name == 'quiz')   ){  
    // if (from.name == 'quiz'){  
      next(false);
  
  }else{  
    next(); 
  
  }

});


export default router
