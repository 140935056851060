import { createStore } from 'vuex'

const store = createStore({
  state() {
    return {
      debug: false,
      language: "de",
      loaded: false,
      started: false,
      playedfragen: [],  // [1,2,3,4,5,6,7,8]
      currfrage: 0,
      cntfragen: 0,
      content: "",
      page: -1,
      portrait: false,
      gamestate: 0,  //0
      gamepoints: 0,
      volume: 0.95,
    }
  },
  
  mutations: {
    resetFragen(state) {
      // console.log("resetFragen");
      state.gamestate =  0;
      state.gamepoints = 0;
      state.currfrage = 0;
      state.cntfragen = 0;
      state.playedfragen = [];
    },

    mutationName(state) {
      state.setTimeoutObject = setTimeout((...state) => {
        state.changeSomething = "state_changed";
      }, state.rfidDelay);
    },
    
    setAppContentM(state, content) {
      console.log("setAppContentM in store commit:" + content);
      state.content = content;
      // console.log(state.activeInfo);
    },
    
    setAppLoadedM(state) {
      console.log("setAppLoadedM");
      state.loaded = true;
      // console.log(state.activeInfo);
    },
    
    setPageM(state, payload) {
      state.page = payload.page;
    },
    
    switchDebugM(state) {
      state.debug = !state.debug;
    },

    setRFIDM(state, rfid) {
      // console.log("setRFIDM in store commit:" + rfid);
      state.rfidArray = rfid.split("|");
      console.log(state.rfidArray);
      // if (rfidArray[0] != "") {
      //   state.rfidLinks = rfidArray[0];
      //   state.lastUpdateLinks = t.elapsed();
      // } else {
      //   state.rfidLinks = ""
      // }
      // if (rfidArray[1] != "") {
      //   state.rfidRechts = rfidArray[1];
      //   state.lastUpdateRechts = t.elapsed();
      // } else {
      //   state.rfidRechts = ""
      // }
      // console.log("lastUpdateLinks:", state.lastUpdateLinks);
    },

  },
  actions: {
    resetFragen({ commit }) {
      // console.log("resetFragen");
      commit("resetFragen");
      // state.currfrage = 0;
      // state.cntfragen = 0;
    },


    switchLanguageByID({ commit }) {
      // console.log("switchLanguage in store");
      commit("switchLanguageByIDM");
    },
    resetAppTimeout({ commit }) {
      // console.log("resetAppTimeout in store");
      commit("resetAppTimeoutM"); // alles wieder auf anfang
    },
    setAppTimeout({ commit }) {
      console.log("setAppTimeout in store");
      commit("setAppTimeoutM"); // alles wieder auf anfang
    },
    setAppLoaded({ commit }) {
      // console.log("setAppLoaded in store");
      commit("setAppLoadedM"); // alles wieder auf anfang
    },
    setAppContent({ commit }, content) {
      // console.log("setAppContent in store");
      commit("setAppContentM", content);
    },
    setRFID({ commit }, rfid) {
      // console.log("setRFID", rfid);
      commit("setRFIDM", rfid); // in der mutation kann der wert geändert werden
    },
    switchDebug({ commit }) {
      // console.log("switchDebug");
      commit("switchDebugM");
    },
    setPage({ commit }, payload) {
      // console.log("setPage", payload);
      commit("setPageM", payload); // in der mutation kann der wert geändert werden
    },
  },
  modules: {},
  getters: {
    getAppLoaded(state) {
      // console.log("getAppLoaded from store");
      return state.loaded;
    },
    
    getDebugFlag(state) {
      console.log("getDebugFlag from store", state.debug);
      return state.debug;
    },
    
    getPage(state) {
      // console.log("getDebugFlag from store");
      return state.page;
    },

    getOrientation(state) {
      // console.log("getDebugFlag from store");
      return state.portrait;
    },


    // page(state) {
    //   return state.page;
    // }
  },
});


export default store;