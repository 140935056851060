<template>

    <div v-if="jsonLoaded==true" id="content-grid" :style="bgStyleComp" class=" bg-bgcolor h-full m-auto bg-center bg-no-repeat bg-cover">
   
    <HeaderComp></HeaderComp>

      <div id="main-content">

        <router-view v-if="jsonLoaded==true" v-slot="{ Component }" :key="(route.fullPath && route.params.projectId)" :language="store.state.language" >                       
            <transition name="fadepage" mode="out-in">
                <component :is="Component" class="animatednotused" :key="route.fullPath"/>
            </transition>
        </router-view>

      </div>


      <FooterComp v-if="jsonLoaded==true" :content="store.state.content" :language="store.state.language"></FooterComp>
        
  </div>


  <!-- <button class="absolute bottom-10" @click="toggleModal">Toggle!</button>
  <ModalComponent :activated="modalActive"></ModalComponent> -->
  
  <VueSplash v-if="jsonLoaded==true" :titel='store.state.content.titel2' />
  <VueRotation></VueRotation>

  <AddToHome></AddToHome>

</template>



<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router'  // 
// import { publicPath } from '../vue.config';

import FooterComp from './components/FooterComp.vue';
import HeaderComp from './components/HeaderComp.vue';
import AddToHome from './components/AddToHome2.vue';
// import ModalComponent from './components/Modal.vue';
import VueRotation from './components/VueRotation.vue'
import VueSplash from './components/VueSplash.vue'



export default {

  name:"app",

  components: {
    AddToHome,
    // ModalComponent,
    VueSplash,
    VueRotation,
    FooterComp, 
    HeaderComp, 
  },

  setup() {

    const modalActive = ref(true);
    let jsonLoaded = ref(false);

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    
    let bgNum = "1";

    // store.state.language ="de";
    // console.log(publicPath);
    // let toLoads = [];
    // const db = 

    //   let db = {
    //     db: null,
    //     allDocs: [],
    //     init() {
    //         var self = this;
    //         return new Promise((resolve, reject) => {
    //             this.db = new PouchDB('video');
    //             this.db.allDocs({include_docs: true, descending: true}, function (err, doc) {
    //                 self.allDocs = doc.rows;
    //                 resolve()
    //             });
    //         })
    //     },
    // }
    const resetApp = () => {
      jsonLoaded.value = false
    }

    const bgStyleComp = computed(() => {

      // console.log(store.state.content.bg);
      bgNum = store.state.content.bg[Math.floor(Math.random() * store.state.content.bg.length)]
      // console.log(bgNum);

      return {
        backgroundImage: `url('./hg/hg_${bgNum}.webp')`
      };
    })


    // const bgStyle = () => {
    //   // let bg = `background-image: url(~@/assets/hg/hg_${bgNum}.webp);`
    //   // let bg = `background-image: url('~@/assets/hg/hg_4.webp');`
    //   // console.log(bg, bgNum);
    //   // return "bg-[url('~@/assets/hg/hg_1.webp')]"
    //   return {
    //     backgroundImage: `url('~@/assets/hg/hg_${bgNum}.webp')`
    //   };
    // }

    const contentLoad = () => {
      
      if (process.env.NODE_ENV === "production"){
        var base_url = "https://emil.blcms.de/"; 
        base_url = window.location.origin  + "/"  // + publicPath //   
        console.log("base_url", base_url)
      } else {
        base_url = window.location.origin + "/" // + publicPath //   
        // base_url =  "." + publicPath
      }

      store.state.baseurl = base_url;

        fetch(base_url + 'content.json', { method: 'get', headers: { 'content-type': 'application/json', 'Cache-Control': 'no-cache' } } )
          .then(res => {
              if (!res.ok) {
                console.log("loaded NOOK")
                // create error instance with HTTP status text
                const error = new Error(res.statusText);
                error.json = res.json();
                throw error;
              }
              return res.json();
          })
          .then(json => {

              store.state.content = json;
              // console.log("debug", content.value.debug)
              // imageArray.push("./static/images/bluelemon_logo.jpg");
              // imageArray.push(base_url + "/images/bluelemon_logo.jpg");
              // // for(i = 0; i < contentUi.menu.length; i++) {
              // //   // var menu = this.contentUi.menu[i];          
              // // }
              // loadOneImage(imageArray[0])
              // ??? var promises = imageArray.map(loadOneImage);
              // ??? Promise.all(promises.map(p => p.catch(e => e)));
              // checkCookies()
              jsonLoaded.value = true;

          })
          .catch(err => {
              console.log("error", err)
              
              const error = new Error();
              error.value = err;
              if (err.json) {
                return err.json.then(json => {
                  error.value.message = json.message;
                });
              }
          })
          .then(() => {
            jsonLoaded.value = true;
          });

    }

    const toggleModal = () => {
      console.log("toggleModal");
      modalActive.value = !modalActive.value;
    }

    // const getRandom = () => {
    //   return "4";
    // }


    onMounted( ( ) => {

      resetApp()

      if (jsonLoaded.value == false){
        // console.log("content laden init")
        contentLoad();
      }

    })


    return {
      toggleModal,

      bgStyleComp,
      router,
      route,
      store,
      modalActive,
      jsonLoaded,

    }

  },
}
</script>



<style>
html {
  height: 100%;
}

/* body {
  background-color: green;
  height: 100%;
  margin: auto;
} */
</style>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  margin: auto;
}

#content-grid {
  // background-color: red;
  text-align: center;
  margin: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 5rem auto 6rem;   /* erster Wert Abstand nach oben */ /* fit-content(8rem)  5rem */
  grid-template-areas: "header"
                       "content"
                       "footer";
  height: 100%;
  overflow-y: scroll;

  // @media (min-width: 640px) {
  //   grid-template-rows: 10rem auto 3rem;
  // }

  @media (min-width: 640px) {
    grid-template-rows: 9.0rem auto 2.6rem;
  }
  @media (min-width: 768px) {
    grid-template-rows: 9.0rem auto 3.6rem;
  }
  @media (min-width: 1024px) {
    grid-template-rows: 9.0rem auto 4.5rem;
  }
  
}

#content-grid::-webkit-scrollbar {
    width: 10px;
}
  
#content-grid::-webkit-scrollbar-track {
    background: #042A50;
}
  
#content-grid::-webkit-scrollbar-thumb {
    background-color: #0c4c8b;
    border-radius: 0;
    border: 0px solid #dadada
}
    

// #content-grid::-webkit-scrollbar {
//         width: 20px;
//     }
      
//     #content-grid::-webkit-scrollbar-track {
//         background: #042A50;
//     }
      
//     #content-grid::-webkit-scrollbar-thumb {
//         background-color: #0c4c8b;
//         border-radius: 0;
//         border: 0px solid #dadada
//     }



main {
  grid-area: main;
  // margin-top: 16.5rem;
  // background-color: yellow;
  @media (min-width: 768px) {
    // margin-top: 9.3rem;
  }

}

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }

</style>