<template>

  <div class="grid justify-items-center">
      
    <transition name="pagefade" mode="out-in" appear >
    
      <div class="grid grid-cols-1 pt-6 w-full self-center justify-items-center" v-if="store.state.started==true"> <!--v-if="store.state.gamestate==0" -->

            <div class="relative w-full ">   <!-- justify-items-center-->
              <div class="relative w-full">
                    <img src="/img/yellow.webp" class="opacity-0 w-full" alt="">
                </div>

                <!-- v-if="ready==false" -->
                <transition name="fadepage" mode="out-in" appear>
                  <VueLoading v-if="ready==false" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" :key="ready" :text="store.state.content.loading"></VueLoading>
                </transition>

                <!-- <div></div> @frame="onFrame()" @click="onPlayAnimation()"  -->
                <dotlottie-player class="w-full absolute top-0 left-0" ref="lottie" @complete="onComplete()" @load="onLoad()" @ready="onReady()"   background="#ff00ff00" src="./lottie/intro.lottie" ></dotlottie-player>
              
              </div>

            <!-- <dotlottie-player ref="lottie" @complete="onComplete()" @load="onLoad()" @ready="onReady()"  @click="onPlayAnimation()" @frame="onFrame()" background="#ff00ff00" src="./lottie/intro.lottie" ></dotlottie-player> -->

            <!-- style="width: 400px;  margin: auto;" autoplay="false"  --> <!-- @timeupdate="audioEnded" -->  <!-- :src=" getBaseAudioName() + '.mp3'" -->
            <audio ref="audioPlayer" crossorigin playsinline @ended="audioEnded" :volume="store.state.volume">  
                <source src="/audio/0.mp3" type="audio/mp3" />
            </audio>

        <!-- <p>die Begrüssung Emil in gross</p> -->
        <button class="btn" @click="goQuiz()">zum Quiz</button>
      </div>

    </transition>
    

  </div>

</template>



<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter} from 'vue-router';

import '@johanaarstein/dotlottie-player-light';

import VueLoading from '../components/VueLoading.vue';

// import { gsap } from "gsap";


export default {
  
  name: 'HomeView',
  
  components: {
    VueLoading,
  },

  setup() {

    const store = useStore();
    const router = useRouter();

    const lottie = ref('');
    const audioPlayer = ref('');
    const ready = ref(false);

    
    // let fr = 0;

    const result = ref('');
    const cacheName = "test-pwa-cache";
    let st1 = 0;

    const goQuiz = () => {
      store.dispatch('resetFragen');
      store.state.page = 2;
      router.push({ name: 'quiz', props: true})
      // console.log("frame", fr)
    }


    const onComplete = () => {
      console.log("complete")
    }

    // const onFrame = () => {
    //   // fr++;
    //   // console.log("frame", fr)
    // }

    const onLoad = () => {
      // console.log("load")
      // fr = 0;
      // // lottie.value.pause();
      // onPlayAnimation();
    }

    const onReady = () => {
      console.log("ready")
      ready.value = true;

      // fr = 0;
      // lottie.value.pause();
      onPlayAnimation();
    }

    const onPlayAnimation = () => {
      // fr = 0;
      // console.log("home onPlayAnimation", lottie.value)
      if ( (lottie.value == undefined) || (lottie.value == null)){
        return
      }
      lottie.value.seek(0);
      lottie.value.play();
      // audioPlayer.value.play();
      setTimeout(() => {
          if (audioPlayer.value != null){
            // console.log(audioPlayer.value)
            audioPlayer.value.play();
          }
          
      }, 800); //800
    }

    const addSingleFile = async () => {
      
      if ('caches' in window) {

        // erst checken ob das File geladen ist
        const cache = await caches.open(cacheName);        
        let is = await cache.match("/lotti/intro.lottie").then(function(response) {
            // response = !!response;
            // console.log(response)
            return response;  
        });
        
        // console.log("is", is);

        if (is != undefined){
          // console.log("is.ok", is.ok);
          is = is.ok;
        } else {
          is = false;
        }
        
        // is.then((value) => {
        //     console.log(value, value?.key1);
        // });

        console.log("is", is);

        if (is == false){
          try {
            // const cache = await caches.open(cacheName);
            await cache.add("/lottie/intro.lottie")
            result.value = ("/lottie/intro.lottie was cached on " + cacheName);

          } catch (error) {
            result.value = ("Error while caching a single file");
          }    
        } else {
          result.value = ("File bereits vorhanden!");
        }
        
      } else {
        result.value = ("Cache Storage not available");
      }
    }

    onMounted( ( ) => {

      console.log("mounted")
      // st1 = setTimeout(() => {
      //   fr=0;
      //   lottie.value.seek(0);
      //   lottie.value.play();
      // }, 1000);
      
    })

    onUnmounted(() => {
      clearTimeout(st1)
    })

    const deleteFile = async () => {
      if ('caches' in window) {
        await caches.delete(cacheName);
        result.value = (cacheName + " was deleted");
      } else {
        result.value = ("Cache Storage not available");
      }
    }


    return {
      
      lottie,
      audioPlayer,
      // fr,
      result,
      store,
      ready,

      onComplete,
      // onFrame,
      onLoad,
      onReady,
      onPlayAnimation,
      addSingleFile,
      deleteFile,
      goQuiz,
      
    }

  }

}
</script>