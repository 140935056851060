<template>

     <header id="main-header" class="grid grid-col self-center justify-items-center place-content-center bg-bgcolor text-white" @click="headerClick()">
        <h1 class="w-6/7 pointer-events-none"  v-html="store.state.content.titel"></h1>
     </header>
     <!-- <div class="absolute left-0 top-0">getScreensize()</div> -->

</template>



<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

import { ref } from 'vue';


export default {
  
  name: 'HeaderView',
  
  components: {
   
  },

  setup() {
      
   const store = useStore();
   const router = useRouter();

   let clickCnt = ref(0);
   let lastClick = 0;



   const headerClick  = () => {
      
      const d = new Date();
      let time = d.getTime();
      // console.log("headerClick", clickCnt.value, time, lastClick);

      if (lastClick > (time-250) ){
         
         lastClick = time;
         clickCnt.value++;
         if (clickCnt.value >= 4){
            goHome();
            clickCnt.value = 0
         }
         
      } else {
         lastClick = time;
         clickCnt.value = 1;
      }
   }


   const goHome = () => {
      // emitter.emit("GO_HOME", "message from header" );
      console.log("goHome")
      store.state.page = -1;
      window.scrollTo(0, 0);
      store.state.started=false;
      router.push({ name: 'home', params: { }, props: true});
   }

   // const getScreensize = () => {
   //   return sc
   // }

      return {
         store,
         goHome,
         headerClick,
      }

   }

}
</script>